<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <!-- EOC -->

          <BookInfoDialog
            v-if="bookInfoDialog"
            :callbackClose="() => (bookInfoDialog = false)"
            :showDialog="true"
            :data="bookData"
          />

          <InfoDialog
            v-if="chapterErrorDialog"
            :callbackClose="() => (chapterErrorDialog = false)"
            :showDialog="true"
            :message="$t('string.chapterStarMsg')"
            :image="require('@/assets/island/hamochi_sad.png')"
            :totalStar="
              book.PlayerBook && book.PlayerBook.length > 0
                ? book.PlayerBook[0].totalStar
                : 0
            "
            :unlockStar="unlockStar"
          />

          <div class="pe-2 ps-3 app-title text-h5" v-if="book">
            {{ $t("string.storyBook") }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>

          <div class="px-5">
            <div class="text-h5">
              {{ book.name }}
            </div>

            <div class="d-flex justify-space-between py-2 mb-4 align-center">
              <v-btn
                small
                color="light-blue"
                dark
                @click="bookInfoDialog = true"
              >
                {{ $t("string.info") }}
                <v-icon small class="ms-1"> mdi-information-outline </v-icon>
              </v-btn>
              <div class="d-flex align-center ticket-card">
                <v-icon color="amber"> mdi-star </v-icon>
                <div class="text-h5 ps-2 game-title">
                  {{
                    book.PlayerBook && book.PlayerBook.length > 0
                      ? book.PlayerBook[0].totalStar
                      : 0
                  }}
                </div>
              </div>
            </div>
            <div v-if="quests.length > 0">
              <div class="text-h5 abc-title" id="QUEST">
                {{ $t("string.quest") }}
              </div>
              <v-carousel
                :show-arrows="false"
                height="auto"
                hide-delimiter-background
                class="pb-8"
                draggable
                :value="currentQuest"
              >
                <v-carousel-item
                  v-for="item in quests"
                  :key="item.key"
                  :value="item.key"
                >
                  <v-card class="my-2 list-card" flat>
                    <div class="d-flex flex-no-wrap align-center">
                      <div
                        class="text-center mx-4 mt-2"
                        v-for="(rwd, j) in item.Reward"
                        :key="j + 'qi'"
                      >
                        <v-img
                          contain
                          max-height="50"
                          max-width="50"
                          :src="rwd.Item.images.icon.url"
                        >
                        </v-img>
                        <div class="me-1">+{{ rwd.quantity }}</div>
                      </div>

                      <div style="width: 100%" class="pa-2">
                        <div class="text-h6">
                          {{ item.name }}
                        </div>
                        <div class="d-flex flex-no-wrap align-center">
                          <div style="width: 100%" class="pe-3">
                            <v-progress-linear
                              :value="item.progress"
                              color="green"
                              height="20"
                              rounded
                            >
                              {{ item.numerator }} /
                              {{ item.denominator }}</v-progress-linear
                            >
                          </div>
                          <v-icon
                            large
                            v-if="item.PlayerQuest.timestampClaimed"
                            color="green"
                          >
                            mdi-check-circle
                          </v-icon>
                          <v-btn
                            v-else-if="item.progress == 100"
                            color="yellow"
                            class="text-h6 constant-tilt-shake"
                            @click="claimQuest(item)"
                            :loading="api.isLoading && selectedItem == item"
                          >
                            {{ $t("action.claim") }}
                          </v-btn>
                          <v-btn v-else color="grey" dark depressed>
                            <v-icon> mdi-lock </v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-carousel-item>
              </v-carousel>
            </div>
            <v-divider class="my-3" v-if="quests.length > 0"></v-divider>
            <div v-if="chapters.length > 0">
              <div class="text-h5 my-4 abc-title">
                {{ $t("string.chapters") }}
              </div>
              <v-card
                v-for="(item, i) in chapters"
                :key="i + 'all'"
                class="my-2 list-card"
                :id="'CH' + item.sort"
                flat
                @click="clickCard(item, i)"
              >
                <div class="d-flex flex-no-wrap">
                  <div
                    style="width: 100%"
                    class="d-flex flex-column justify-space-between pa-2"
                  >
                    <div class="d-flex align-center">
                      <v-icon
                        color="green"
                        v-if="
                          item.PlayerChapter.length > 0 &&
                          item.PlayerChapter[0].isRead
                        "
                      >
                        mdi-check-circle
                      </v-icon>
                      <v-icon v-else>mdi-circle-outline</v-icon>
                      <div class="mx-1">
                        {{
                          JSON.parse(item.translatableSection)[$_getLocale()]
                            ? JSON.parse(item.translatableSection)[
                                $_getLocale()
                              ]
                            : JSON.parse(item.translatableSection)[subjectLang]
                        }}
                      </div>

                      <v-spacer></v-spacer>

                      <div v-if="item.PlayerChapter.length > 0">
                        <v-icon
                          color="amber"
                          v-for="(star, i) in item.totalCollectableStar"
                          :key="i"
                          >{{
                            item.PlayerChapter[0].topStar > i
                              ? "mdi-star"
                              : "mdi-star-outline"
                          }}</v-icon
                        >
                      </div>
                      <div
                        v-else-if="item.minStarToUnlock"
                        class="d-flex align-center"
                      >
                        <v-icon>mdi-star</v-icon>
                        <span class="ml-1">{{ item.minStarToUnlock }}</span>
                      </div>
                    </div>
                    <div class="d-flex align-center mt-3">
                      <div class="text-h5 mx-2">
                        {{
                          item.PlayerChapter.length > 0
                            ? JSON.parse(item.translatableName)[$_getLocale()]
                              ? JSON.parse(item.translatableName)[$_getLocale()]
                              : JSON.parse(item.translatableName)[subjectLang]
                            : "???"
                        }}
                      </div>
                      <v-divider></v-divider>
                      <v-btn
                        v-if="item.PlayerChapter.length > 0"
                        color="blue"
                        dark
                        class="text-h6"
                      >
                        <!-- @click="read(item)" -->
                        {{ $t("string.read") }}
                      </v-btn>
                      <v-btn
                        v-else-if="
                          (book.PlayerBook[0].totalStar >=
                            item.minStarToUnlock &&
                            i == 0) ||
                          (book.PlayerBook[0].totalStar >=
                            item.minStarToUnlock &&
                            i > 0 &&
                            chapters[i - 1].PlayerChapter.length > 0 &&
                            chapters[i - 1].PlayerChapter[0].isRead)
                        "
                        color="green"
                        dark
                        class="constant-tilt-shake"
                        :loading="api.isLoading && selectedItem == item"
                      >
                        <!-- @click="unlock(item)" -->
                        <v-icon> mdi-lock </v-icon>
                      </v-btn>
                      <v-btn v-else depressed color="grey" dark>
                        <!-- @click="
                          chapterErrorDialog = true;
                          unlockStar = item.minStarToUnlock;
                        " -->
                        <v-icon> mdi-lock </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>

            <div class="d-flex justify-right mt-6">
              <v-divider class="my-3"></v-divider>
              <v-btn
                small
                dark
                color="black"
                v-if="settings.debug"
                @click="resetBook"
              >
                <v-icon color="red" small> mdi-record </v-icon>
                Reset
              </v-btn>
            </div>
          </div>

          <UnlockDialog
            v-if="chapterUnlocked"
            :callbackClose="
              () => {
                chapterUnlocked = false;
                read(selectedItem);
              }
            "
            :showDialog="true"
            :title="$t('string.chapterUnlocked')"
            :message="selectedItem.name"
          />
          <RewardDialog
            v-if="rwdDialog"
            :callbackClose="() => (rwdDialog = false)"
            :showDialog="true"
            :rewards="rewards"
          />
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    RewardDialog: () =>
      import(
        /* webpackChunkName: "component-reward" */ "@/components/ABC/RewardDialog"
      ),
    UnlockDialog: () =>
      import(
        /* webpackChunkName: "component-unlock" */ "@/components/ABC/UnlockDialog"
      ),
    BookInfoDialog: () =>
      import(
        /* webpackChunkName: "book-info-dialog" */ "@/components/BookInfoDialog.vue"
      ),
    InfoDialog: () =>
      import(
        /* webpackChunkName: "component-tutorial-dialog" */ "@/components/InfoDialog.vue"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    unlockStar: 0,
    chapterErrorDialog: false,
    currentQuest: null,
    bookInfoDialog: false,
    bookData: [],
    rwdDialog: false,
    book: {},
    chapters: [],
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    quests: [],
    selectedItem: {
      name: "",
      section: "",
    },
    chapterUnlocked: false,
    subjectLang: "en",
    rewards: [],
  }),
  async created() {
    await this.browse();
    await this.getQuest();
    this.scrollToTarget();

    if (this.book.subject == "Mandarin") {
      this.subjectLang = "zh";
    } else if (this.book.subject == "Malay") {
      this.subjectLang = "ms";
    } else {
      this.subjectLang = "en";
    }
  },
  mounted() {
    //
  },
  methods: {
    async browse() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/abc/chapter/browse";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.book = resp.Book;
        this.chapters = resp.Chapter;

        this.bookData = [
          {
            key: "date",
            title: { en: "Date", ms: "Tarikh", zh: "日期" },
            value: {
              en: new Date().toISOString().split("T")[0],
              ms: new Date().toISOString().split("T")[0],
              zh: new Date().toISOString().split("T")[0],
            },
          },
          {
            key: "title",
            title: { en: "Title", ms: "Tajuk", zh: "标题" },
            value: {
              en: JSON.parse(this.book.translatableName)["en"]
                ? JSON.parse(this.book.translatableName)["en"]
                : JSON.parse(this.book.translatableName)[
                    this.getSubjectLang(this.book)
                  ],
              ms: JSON.parse(this.book.translatableName)["ms"]
                ? JSON.parse(this.book.translatableName)["ms"]
                : JSON.parse(this.book.translatableName)[
                    this.getSubjectLang(this.book)
                  ],
              zh: JSON.parse(this.book.translatableName)["zh"]
                ? JSON.parse(this.book.translatableName)["zh"]
                : JSON.parse(this.book.translatableName)[
                    this.getSubjectLang(this.book)
                  ],
            },
          },
          {
            key: "type",
            title: {
              en: "Type of Book",
              ms: "Jenis Buku",
              zh: "书籍类型",
            },
            value: { en: "e-Book", ms: "e-Buku", zh: "电子书" },
          },
          {
            key: "category",
            title: { en: "Category", ms: "Kategori", zh: "类别" },
            value: { en: "Fiction", ms: "Fiksyen", zh: "小说" },
          },
          {
            key: "page",
            title: { en: "Page", ms: "Halaman", zh: "页" },
            value: {
              en: this.book._count.Page,
              ms: this.book._count.Page,
              zh: this.book._count.Page,
            },
          },
          {
            key: "ISBN",
            title: { en: "ISBN", ms: "ISBN", zh: "ISBN" },
            value: { en: "-", ms: "-", zh: "-" },
          },
          {
            key: "author",
            title: { en: "Author", ms: "Penulis", zh: "作者" },
            value: {
              en: this.book.author,
              ms: this.book.author,
              zh: this.book.author,
            },
          },
          {
            key: "publisher",
            title: { en: "Publisher", ms: "Penerbit", zh: "出版社" },
            value: {
              en: "Hamochi Sdn. Bhd.",
              ms: "Hamochi Sdn. Bhd.",
              zh: "Hamochi Sdn. Bhd.",
            },
          },
          {
            key: "year",
            title: {
              en: "Publication Year",
              ms: "Tahun Penerbitan",
              zh: "出版年份",
            },
            value: {
              en: new Date(this.book.timestampCreated).getFullYear(),
              ms: new Date(this.book.timestampCreated).getFullYear(),
              zh: new Date(this.book.timestampCreated).getFullYear(),
            },
          },
          {
            key: "language",
            title: { en: "Language", ms: "Bahasa", zh: "语言" },
            value: {
              en: this.book.subject,
              ms: this.book.subject,
              zh: this.book.subject,
            },
          },
          {
            downloadable: true,
            key: "cover",
            title: { en: "Book Cover", ms: "Kulit Buku", zh: "封面" },
            action: { en: "Download", ms: "Muat Turun", zh: "下载" },
            value: {
              en: JSON.parse(this.book.translatableImage)["en"]
                ? JSON.parse(this.book.translatableImage)["en"].url
                : JSON.parse(this.book.translatableImage)[
                    this.getSubjectLang(this.book)
                  ].url,
              ms: JSON.parse(this.book.translatableImage)["ms"]
                ? JSON.parse(this.book.translatableImage)["ms"].url
                : JSON.parse(this.book.translatableImage)[
                    this.getSubjectLang(this.book)
                  ].url,
              zh: JSON.parse(this.book.translatableImage)["zh"]
                ? JSON.parse(this.book.translatableImage)["zh"].url
                : JSON.parse(this.book.translatableImage)[
                    this.getSubjectLang(this.book)
                  ].url,
            },
          },
        ];
      };
      this.api.params = {
        bookKey: this.$route.params.bookKey,
      };
      await this.$api.fetch(this.api);
    },
    async getQuest() {
      this.quests = [];
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/quest/visit";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.quests = resp.Quest;
        for (let i = 0; i < this.quests.length; i++) {
          if (
            i > 0 &&
            this.quests[i - 1] &&
            this.quests[i - 1].PlayerQuest.timestampClaimed
          ) {
            this.currentQuest = this.quests[i].key;
          }
        }
      };
      this.api.params = {
        model: "Book",
        modelKey: this.$route.params.bookKey,
      };

      await this.$api.fetch(this.api);
    },
    clickCard(chapter, i) {
      if (chapter.PlayerChapter.length > 0) {
        this.read(chapter);
      } else if (
        (this.book.PlayerBook[0].totalStar >= chapter.minStarToUnlock &&
          i == 0) ||
        (this.book.PlayerBook[0].totalStar >= chapter.minStarToUnlock &&
          i > 0 &&
          this.chapters[i - 1].PlayerChapter.length > 0 &&
          this.chapters[i - 1].PlayerChapter[0].isRead)
      ) {
        this.unlock(chapter);
      } else {
        this.chapterErrorDialog = true;
        this.unlockStar = chapter.minStarToUnlock;
      }
    },
    unlock(chapter) {
      this.selectedItem = chapter;
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/abc/chapter/unlock";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
        this.browse();
        this.chapterUnlocked = true;
      };
      this.api.params = {
        chapterKey: chapter.key,
        bookKey: this.book.key,
      };
      this.$api.fetch(this.api);
    },
    claimQuest(item) {
      this.selectedItem = item;
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/quest/open";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.rewards = resp.Reward;
        this.rwdDialog = true;
        this.getQuest();
      };
      this.api.params = {
        key: item.key,
      };
      this.$api.fetch(this.api);
    },
    read(chapter) {
      this.$router.push({
        name: "PageABCBookReader",
        params: { bookKey: this.book.key, chapterKey: chapter.key },
      });
    },
    exit() {
      this.$router.push({
        name: "PageExploreLanding",
      });
    },
    resetBook() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/abc/book/reset";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
        this.exit();
      };
      this.api.params = {
        bookKey: this.$route.params.bookKey,
      };
      this.$api.fetch(this.api);
    },
    getSubjectLang(item) {
      if (item.subject == "Mandarin") {
        return "zh";
      } else if (item.subject == "Malay") {
        return "ms";
      } else {
        return "en";
      }
    },
    scrollToTarget() {
      this.$nextTick(() => {
        const container = document.querySelector(".hmc-app"); // Default scrollable container in Vuetify

        // Scroll to the first quest that matches the condition
        for (const element of this.quests) {
          if (
            element.progress === 100 &&
            !element.PlayerQuest.timestampClaimed
          ) {
            const target = document.getElementById("QUEST");
            if (target) {
              this.smoothScrollTo(container, target, 800, true);
            }
            return;
          }
        }

        // Scroll to the first chapter that matches the condition
        for (const element of this.chapters) {
          if (
            (element.PlayerChapter.length > 0 &&
              !element.PlayerChapter.isRead) ||
            element.PlayerChapter.length === 0
          ) {
            const target = document.getElementById("CH" + element.sort);
            if (target) {
              this.smoothScrollTo(container, target, 800);
            }
            return;
          }
        }
      });
    },
    smoothScrollTo(container, target, duration, isY = false) {
      const start = container.scrollTop;
      const change = isY
        ? target.offsetTop - 80 - start
        : target.offsetTop - 80 - start;
      const startTime = performance.now();

      function animateScroll(currentTime) {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1); // Clamp progress to a max of 1
        container.scrollTop = start + change * easeInOutQuad(progress);

        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        }
      }

      function easeInOutQuad(t) {
        return t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;
      }

      requestAnimationFrame(animateScroll);
    },
  },
};
</script>

<style scoped>
.ticket-card {
  background-color: #fff;
  height: fit-content;
  padding: 0 10px;
}

.pulse:hover {
  animation: none;
}

.pulse {
  animation: beat 0.5s infinite alternate;
  transform-origin: center;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.1);
  }
}

.constant-tilt-shake {
  animation: tilt-shaking 2s infinite;
}

.constant-tilt-shake:hover {
  animation: none;
}

@keyframes tilt-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  3% {
    transform: translate(2px, -2px) rotate(5deg);
  }
  6% {
    transform: translate(0, 0) rotate(0eg);
  }
  9% {
    transform: translate(-2px, -2px) rotate(-5deg);
  }
  12% {
    transform: translate(0, 0) rotate(0deg);
  }
  15% {
    transform: translate(2px, -2px) rotate(5deg);
  }
  18% {
    transform: translate(0, 0) rotate(0eg);
  }
  21% {
    transform: translate(-2px, -2px) rotate(-5deg);
  }
  24% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.menu-button {
  padding: 0 5px;
  cursor: pointer;
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  scale: 1.1;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.list-card {
  padding: 5px;
  color: #785c3a;
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #fff9f1;
  overflow-x: hidden !important;
}

.main-body {
  /*background-color: #c7e5e9;*/
  background-color: rgba(214, 132, 49, 0.3);
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}

.abc-title {
  color: #785c3a;
}
</style>